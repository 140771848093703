import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import {
    isArray, pickBy, pick, find,
} from 'lodash'
import { filterPreferences } from '@/services/filterPreferences'
import filterType from '@/constants/filterType'

export const useFilterStore = defineStore('filter', {
    state: () => {
        return {
            filtersByUuid: {},
            storageKeysByUuid: {},
        }
    },
    getters: {
        filtersForUuid(state) {
            return (uuid) => state.filtersByUuid[uuid]
        },
        requestFiltersForUuid(state) {
            return (filters, uuid) => {
                return pickBy(state.filtersByUuid[uuid], (filterValue, filterId) => {
                    const filter = find(filters, { id: filterId })
                    if (filter?.type !== filterType.select) {
                        return true
                    }
                    // Don't submit filters if all options are selected
                    return filter.options?.length !== filterValue?.length
                })
            }
        },
    },
    actions: {
        async initialiseFilters(filters, storageKey = null) {
            const uuid = uuidv4()
            let value = {}

            // Load filters from local storage if applicable
            if (storageKey) {
                this.storageKeysByUuid[uuid] = storageKey

                value = await filterPreferences.getEnabledFilters(storageKey)

                // Migrate old format to new format, assume first filter is the key
                if (isArray(value) && value.length > 0) {
                    value = {
                        [filters[0].id]: value,
                    }
                    await filterPreferences.setEnabledFilters(storageKey, value)
                }
            }

            // Limit filters to only those that exist
            const filterIds = filters.map((filter) => filter.id)
            this.filtersByUuid[uuid] = pick(value, filterIds)
            return uuid
        },
        async updateFilters(uuid, filters) {
            this.filtersByUuid[uuid] = filters

            // Update filters in local storage if applicable
            if (this.storageKeysByUuid[uuid]) {
                await filterPreferences.setEnabledFilters(this.storageKeysByUuid[uuid], filters)
            }
        },
        async clearFilters(uuid) {
            delete this.filtersByUuid[uuid]
        },
    },
})
