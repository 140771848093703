import { defineStore } from 'pinia'

import {
    get,
    last,
    set,
    clone,
    has,
    isNil,
    each,
    dropRight,
} from 'lodash'

import { isCancel } from 'axios'

import linkType from '@/constants/linkType'
import { api, mock } from '@/services'
import { events } from '@/composables/events'

// axios cancel handler
let controller = null

export const useModuleStore = defineStore('module', {
    state: () => {
        return {
            modules: {},
            loadingId: null,
            fetching: false,
            stack: [],
            readItemIdsByModuleUrl: {},
        }
    },
    getters: {
        currentModule: (state) => get(state.modules, last(state.stack)),
        url: (state) => {
            if (isNil(state.currentModule)) {
                return null
            }
            // return the url from module link data or use id
            return get(state.currentModule, 'link.url')
        },
        mostRecentUrl: (state) => {
            const uuid = findLast(state.stack, (uuid) => {
                return get(state.modules, `${uuid}.link.type`) === linkType.apiComponent
            })
            return get(state.modules, `${uuid}.link.url`)
        },
        currentModulesReadItemIds: (state) => {
            return state.readItemIdsByModuleUrl[state.mostRecentUrl] || []
        },
    },
    actions: {
        loadContent(url, params = {}) {
            // cancel any current requests
            if (controller) {
                controller.abort()
            }

            controller = new AbortController()

            return api.get(url, {
                signal: controller.signal,
                params,
            })
        },
        replaceComponent(component) {
            const id = this.currentModule.id
            this.loadingId = id
            set(this.modules, `${id}.data`, component)
            delay(() => { this.loadingId = null }, 200)
        },
        refreshParent() {
            if (this.stack.length < 2) {
                // Don't do anything if there isn't a parent on the stack
                return
            }
            each(dropRight(this.stack, 2), (id) => {
                // Mark ancestor modules as stale
                this.modules[id].stale = true
            })
            // Load the previous module
            this.loadModule(this.stack[this.stack.length - 2])
        },
        loadModule(id = null, withoutCache = false) {
            // if we already have data, don't show loading indicator, but still fetch in the background
            // if an id is provided, show loading indicator so content is re-rendered
            if (isNil(this.currentModule.data) || id) {
                this.loadingId = id || this.currentModule.id
            }

            if (!id) {
                id = this.currentModule.id
            }

            // cancel any current requests
            if (controller) {
                controller.abort()
            }

            controller = new AbortController()

            // Check if refresh_url was provided in content
            let url = get(this.modules[id], 'data.meta.refresh_url')
            if (!url) {
                // Fallback to original link used to load content
                url = get(this.modules[id], 'link.url')
            }
            if (!url) {
                // No url to refresh
                return
            }

            // Clear module read item ids
            delete this.readItemIdsByModuleUrl[url]

            this.fetching = true
            return api.get(url, {
                signal: controller.signal,
                params: this.currentModule.params,
                headers: withoutCache ? { 'X-NO-CACHE': true } : {},
            })
                .then(({ data }) => {
                    set(this.modules, `${id}.data`, data)

                    this.loadingId = null
                    this.fetching = false
                })
                .catch((error) => {
                    if (!isCancel(error)) {
                        // Only disable loading if not cancelled
                        this.loadingId = null
                        this.fetching = false
                    }
                    throw error
                })
        },
        addModule(module) {
            if (!has(this.modules, module.id)) {
                set(this.modules, module.id, clone(module))
            }
            this.stack.push(module.id)
        },
        replaceLastModule(module) {
            this.stack.pop()
            this.addModule(module)
        },
        removeModules(moduleIds) {
            moduleIds.forEach((removedModuleId) => {
                const removedModule = get(this.modules, removedModuleId)
                // Clear read items when module has been removed
                if (removedModule?.link?.type === linkType.apiComponent) {
                    delete this.readItemIdsByModuleUrl[removedModule.link.url]
                }
                // Delete the module from the store
                if (removedModule?.link?.type === linkType.component) {
                    setTimeout(() => {
                        delete this.modules[removedModuleId]
                    }, 1000)
                }
            })
        },
        removeModulesUntil(nextModuleId) {
            // If next module in stack is stale, refresh it
            if (this.modules[nextModuleId]?.stale) {
                this.loadModule(nextModuleId)
                this.modules[nextModuleId].stale = false
            }

            const itemsToRemove = this.stack.length - this.stack.lastIndexOf(nextModuleId) - 1
            const removedModuleIds = this.stack.splice(-itemsToRemove, itemsToRemove)
            this.removeModules(removedModuleIds)
        },
        removeAllModules() {
            const removedModuleIds = this.stack.splice(0, this.stack.length)
            this.removeModules(removedModuleIds)
        },
        reset() {
            // cancel any current requests
            if (controller) {
                controller.abort()
            }

            this.loadingId = null
            this.fetching = false
            this.stack = []
            this.modules = {}
        },
        async markAsRead(url, id) {
            // Disable marking as read when mocking
            if (mock.mocking()) {
                return
            }

            return api.post(url)
                .then(() => {
                    if (id) {
                        events.emit('read', id)
                        this.addReadItemId(id)
                        console.log('read', url, id)
                    }
                })
        },
        markMultipleAsRead(url, ids) {
            return api.post(url, { ids })
                .then(() => {
                    each(ids, (id) => {
                        events.emit('read', id)
                        this.addReadItemId(id)
                    })
                })
        },
        addReadItemId(id) {
            if (!this.readItemIdsByModuleUrl[this.mostRecentUrl]) {
                this.readItemIdsByModuleUrl[this.mostRecentUrl] = []
            }
            this.readItemIdsByModuleUrl[this.mostRecentUrl].push(id)
        },
        setQueryParams(params) {
            set(this.modules, [this.currentModule.id, 'params'], params)
        },
    },
})
