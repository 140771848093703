<template>
    <IonApp :style="style">
        <IonRouterOutlet
            :animated="!mock.mocking()"
            :animation="navigationAnimation"
        />

        <ImageViewer
            v-if="activeGallery.images"
            :gallery="activeGallery"
        />

        <DNotificationLayout v-if="snackbars.length > 0" class="dark d-snackbar-layout z-[99999]">
            <Snackbar
                v-for="snackbar in snackbars"
                :key="snackbar.id"
                :snackbar="snackbar"
            />
        </DNotificationLayout>
    </IonApp>
</template>

<script setup>
    import { onBeforeMount } from 'vue'
    import { Capacitor } from '@capacitor/core'
    import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area'
    import { DNotificationLayout } from '@digistorm/spark'
    import { statusBar, mock } from '@/services'
    import ImageViewer from '@/components/app/ImageViewer.vue'

    const appStore = useAppStore()
    const deviceStore = useDeviceStore()
    const dashboardStore = useDashboardStore()

    const { snackbars } = useSnackbars()
    const { activeGallery } = useGallery()
    const { navigationAnimation } = useAnimations()

    // Keep a copy of the query / search string the app was launched with
    appStore.setInitialUrlSearch(window.location.search || '')

    const safeAreaTop = ref(0)

    const style = computed(() => {
        const initialSearchParams = new URLSearchParams(appStore.initialUrlSearch)

        if (safeAreaTop.value > 0) {
            return {
                '--ion-safe-area-top': `${safeAreaTop.value}px`,
                '--beyond-safe-area-inset-top': `${safeAreaTop.value}px`,
            }
        }

        switch (initialSearchParams.get('emulate')) {
            case 'iPhone11':
                return {
                    '--beyond-safe-area-inset-bottom': '34px',
                    '--beyond-safe-area-inset-left': '0px',
                    '--beyond-safe-area-inset-right': '0px',
                    '--beyond-safe-area-inset-top': '48px',
                    '--ion-safe-area-bottom': '34px',
                    '--ion-safe-area-left': '0px',
                    '--ion-safe-area-right': '0px',
                    '--ion-safe-area-top': '48px',
                }
            case 'iPhone14Pro':
                return {
                    '--beyond-safe-area-inset-bottom': '34px',
                    '--beyond-safe-area-inset-left': '0px',
                    '--beyond-safe-area-inset-right': '0px',
                    '--beyond-safe-area-inset-top': '59px',
                    '--ion-safe-area-bottom': '34px',
                    '--ion-safe-area-left': '0px',
                    '--ion-safe-area-right': '0px',
                    '--ion-safe-area-top': '59px',
                }
            case 'iPad':
                return {
                    '--beyond-safe-area-inset-bottom': '0px',
                    '--beyond-safe-area-inset-left': '0px',
                    '--beyond-safe-area-inset-right': '0px',
                    '--beyond-safe-area-inset-top': '20px',
                    '--ion-safe-area-bottom': '0px',
                    '--ion-safe-area-left': '0px',
                    '--ion-safe-area-right': '0px',
                    '--ion-safe-area-top': '20px',
                }
            default:
                return {}
        }
    })

    if (Capacitor.isNativePlatform()) {
        statusBar.setOverlaysWebView(true)

        SafeArea.getStatusBarHeight().then(({ height }) => {
            safeAreaTop.value = Math.max(safeAreaTop.value, height)
            console.log('status bar height', height)
        })
        SafeArea.getSafeAreaInsets().then((insets) => {
            safeAreaTop.value = Math.max(safeAreaTop.value, insets.top)
            console.log('insets', insets)
        })
    }

    onBeforeMount(() => {
        const initialSearchParams = new URLSearchParams(appStore.initialUrlSearch)

        switch (initialSearchParams.get('emulate')) {
            case 'iPad':
                deviceStore.forceTabletMode = true
                break
            default:
                break
        }
    })
</script>

<style>
    /**
     * Added a custom class to prevent other uses of modal from breaking
     */
    ion-modal.ds-modal-bottom-sheet {
        --height: auto;
        --width: 100%;
        --max-height: 500px;
        --border-radius: 12px 12px 0 0;
    }

    ion-modal.ds-modal-bottom-sheet.ios {
        --border-radius: 12px;
    }

    ion-modal.ds-modal-filters ion-header {
        padding-top: var(--beyond-safe-area-inset-top, 0);
    }

    ion-modal.ds-modal-filters ion-footer {
        padding-bottom: var(--beyond-safe-area-inset-bottom, 0);
    }

    /**
     * On larger screens, the modal isn't fullscreen and shows centred in the middle of the page
     */
    @media only screen and (min-width: 768px) and (min-height: 600px) {
        ion-modal.ds-modal-filters {
            --border-radius: 12px;
        }

        ion-modal.ds-modal-filters ion-header {
            padding-top: 0;
        }

        ion-modal.ds-modal-filters ion-footer {
            padding-bottom: 0;
        }
    }
</style>
