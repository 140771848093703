<template>
    <div class="pb-[var(--beyond-safe-area-inset-bottom)]" :class="{ 'h-full flex flex-col': useVirtualScroll }">
        <div v-if="hasReadItems" class="flex items-center border-b border-gray-200 justify-between px-4 pb-4 flex-shrink-0">
            <DTabs type="pill" @selected="onTabSelected">
                <DTab name="All" active />
                <DTab name="Unread" />
            </DTabs>

            <span
                v-if="readAllUrl"
                class="text-[--color-700] text-sm font-medium border-b border-[--color-700] mr-4"
                @click="readAll"
            >
                Mark all as read
            </span>
        </div>

        <div v-if="displaySearch" class="flex items-center justify-between pb-2 px-4 flex-shrink-0" :class="{ 'pt-2': !hasReadItems }">
            <DField id="Search">
                <DInput
                    v-model="search"
                    left-icon="magnifying-glass"
                    placeholder="Search"
                    rounded
                />
            </DField>
        </div>

        <teleport v-if="headerContextualElement" :to="headerContextualElement">
            <FiltersBar v-if="filters.length > 1" :uuid="filterUuid" :filters="filters" />
        </teleport>

        <div v-if="searching || filterFetching || moduleStore.fetching" class="flex justify-center pt-[100px] flex-shrink-0">
            <UILoading class="w-12 h-12" />
        </div>

        <div v-if="!moduleStore.fetching && !searching && !filterFetching" data-cy="list" ref="listContent" :class="{ 'overflow-hidden flex flex-col h-auto w-full flex-1': useVirtualScroll }">
            <component
                :is="useVirtualScroll ? VirtualModuleComponents : ModuleComponents"
                :modules="filteredModules"
                :pagination-url="paginationUrl"
            >
                <template v-slot:empty>
                    <template v-if="unread">
                        <Empty icon="empty-list">
                            <template v-slot:subtitle>
                                You have no unread updates.
                            </template>
                        </Empty>
                    </template>
                    <template v-else-if="filtering || search">
                        <!-- No items after filtering -->
                        <Empty icon="empty-search">
                            <template v-slot:title>
                                No result found.
                            </template>

                            <template v-slot:subtitle>
                                Try changing the filters or search term.
                            </template>
                        </Empty>
                    </template>
                    <template v-else>
                        <!-- No items -->
                        <slot name="no-items">
                            <Empty />
                        </slot>
                    </template>
                </template>
            </component>
        </div>

        <template v-if="headerMenuElement">
            <Teleport :to="headerMenuElement">
                <FiltersButton v-if="filters.length" :uuid="filterUuid" :filters="filters" />
            </Teleport>

            <Teleport :to="headerMenuElement">
                <div v-if="moreMenuItems.length" :id="moreModalUuid" class="w-[38px] h-[38px] flex items-center justify-center rounded-full">
                    <PhIcon icon="dots-three-vertical" weight="bold" :size="24" />
                </div>
            </Teleport>

            <IonModal v-if="moreMenuItems.length" ref="modal" :trigger="moreModalUuid" :initial-breakpoint="1" :breakpoints="[0, 1]" class="ds-modal-bottom-sheet">
                <div class="py-6">
                    <div>
                        <UISimple
                            v-for="(item, index) in moreMenuItems"
                            :key="`menu-item-${index}`"
                            :title="item.title"
                            :icon="{ icon: item.icon, icon_url: null, color: null }"
                            @click="openMoreMenuItem(item)"
                        />
                    </div>
                </div>
            </IonModal>
        </template>
    </div>
</template>

<script setup>
    import {
        DTabs, DTab, DField, DInput,
    } from '@digistorm/spark'
    import { v4 as uuidv4 } from 'uuid'
    import {
        filter, debounce, keys, keyBy,
    } from 'lodash'
    import axios from 'axios'
    import PhIcon from '@/components/app/PhIcon.vue'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'
    import ModuleComponents from '@/components/ModuleComponents.vue'
    import VirtualModuleComponents from '@/components/VirtualModuleComponents.vue'
    import Empty from '@/components/ui/Empty.vue'
    import FiltersButton from '@/components/filters/FiltersButton.vue'
    import FiltersBar from '@/components/filters/FiltersBar.vue'
    import { useFilterStore } from '@/stores'
    import { useFilters } from '@/composables'

    const props = defineProps({
        config: Object,
    })

    const moduleHelpers = useModuleHelpers()
    const moduleStore = useModuleStore()
    const filterStore = useFilterStore()
    const { applyLocalFilter } = useFilters()

    const headerMenu = inject('headerMenu')
    const modal = ref(null)
    const searchFilter = ref(null)
    const readFilter = ref(null)

    const filterUuid = ref(null)
    const search = ref('')
    const searching = ref(false)
    const unread = ref(false)
    const listContent = ref(null)
    const moreModalUuid = ref(uuidv4())
    const filterFetching = ref(false)

    const listConfig = ref(null)

    const paginationUrl = computed(() => listConfig.value.meta?.next)
    const useVirtualScroll = computed(() => listConfig.value.meta?.virtual_scroll)
    const hasReadItems = computed(() => listConfig.value.meta?.read_filter)
    const readAllUrl = computed(() => listConfig.value.meta?.read_multiple_url)
    const displaySearch = computed(() => !!(listConfig.value.meta?.search))
    const searchUrl = computed(() => listConfig.value.meta?.search?.url)
    const searchProperties = computed(() => listConfig.value.meta?.search?.properties)
    const searchType = computed(() => listConfig.value.meta?.search?.type)
    const moreMenuItems = computed(() => listConfig.value.meta?.menu_items ?? [])
    const filters = computed(() => listConfig.value.meta?.filters ?? [])
    const filtersById = computed(() => keyBy(filters.value, 'id'))

    const headerMenuElement = computed(() => {
        return headerMenu?.value?.$el?.querySelector('#header-menu')
    })

    const headerContextualElement = computed(() => {
        return headerMenu?.value?.$el?.querySelector('#header-contextual')
    })

    const activeFilters = computed(() => {
        return filterStore.filtersForUuid(filterUuid.value)
    })

    const filtering = computed(() => {
        return keys(activeFilters.value).length > 0
    })

    const localItemFilter = computed(() => {
        if (!filtering.value || listConfig.value.meta?.filter_url) {
            // Don't filter locally if:
            // - there are no selected filter options
            // - or all options selected
            // - or using remote filtering
            return null
        }

        return (item) => applyLocalFilter(filtersById.value, activeFilters.value, item.meta?.tags)
    })

    const combinedFilter = computed(() => {
        if (!localItemFilter.value && !readFilter.value && !searchFilter.value) {
            return null
        }
        return (item) => {
            return (localItemFilter.value?.(item) ?? true)
                && (readFilter.value?.(item) ?? true)
                && (searchFilter.value?.(item) ?? true)
        }
    })

    const filterModules = (modules) => {
        return flatMap(modules, (module) => {
            if (module.component !== 'GroupListItem') {
                return !combinedFilter.value || combinedFilter.value(module)
                    ? [module]
                    : []
            }

            const children = filterModules(module.items ?? [])
            if (!children.length) {
                return []
            }

            return [
                omit(module, 'items'),
                ...children,
            ]
        })
    }

    const filteredModules = computed(() => filterModules(listConfig.value.items ?? []))

    const filtersChanged = (value, oldValue) => {
        if (!oldValue || !listConfig.value.meta?.filter_url) {
            return
        }
        const requestFilters = filterStore.requestFiltersForUuid(filters.value, filterUuid.value)
        moduleStore.setQueryParams({
            ...(moduleStore.currentModule?.params ?? {}),
            filters: requestFilters,
        })
        filterFetching.value = true
        moduleStore.loadContent(listConfig.value.meta?.filter_url, { filters: requestFilters })
            .then(({ data }) => {
                listConfig.value = {
                    ...data,
                    items: moduleHelpers.addIdsToModules(data.items ?? []),
                }
                filterFetching.value = false
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return
                }
                filterFetching.value = false
            })
    }

    const openMoreMenuItem = (item) => {
        moduleHelpers.handleClick(item)
            .finally(() => {
                modal.value.$el?.dismiss(null, 'cancel')
            })
    }

    const onTabSelected = (index) => {
        if (index === 0) {
            readFilter.value = null
            unread.value = false
        } else {
            unread.value = true
            readFilter.value = (item) => {
                return (item.data?.read ?? false) === false
            }
        }
    }

    const readAll = () => {
        const unreadItems = filter(listConfig.value.items, (item) => !item?.data?.read)
        each(unreadItems, (item) => {
            item.data.read = true
        })
        moduleStore.markMultipleAsRead(readAllUrl.value, map(unreadItems, 'meta.id'))
    }

    const debounceSearch = debounce((searchVal) => {
        moduleStore.loadContent(
            searchUrl.value,
            searchVal.trim() ? { search: searchVal } : {},
        )
            .then(({ data }) => {
                listConfig.value = {
                    ...data,
                    items: moduleHelpers.addIdsToModules(data.items ?? []),
                }
            })
            .finally(() => {
                searching.value = false
            })
    }, 600)

    watch(search, (searchVal) => {
        const isRemote = searchType.value === 'remote'
        if (!searchVal && !isRemote) {
            searchFilter.value = null
            return
        }

        if (!isRemote) {
            const query = toLower(searchVal)
            searchFilter.value = (item) => {
                return some(searchProperties.value, (property) => {
                    return toLower(get(item, property)).includes(query)
                })
            }
            return
        }

        if (searchUrl.value) {
            searching.value = true
            // Search using provided url
            debounceSearch(searchVal)
            return
        }

        // Search using base module url
        moduleStore.setQueryParams({
            ...(moduleStore.currentModule?.params ?? {}),
            search: searchVal,
        })
        moduleStore.loadModule()
    })

    // Adding ids in watcher instead of computed property
    // Computed properties are recalculated in some situations and we don't want to generate new ids
    watch(() => props.config, (newVal) => {
        if (!newVal) {
            return
        }
        listConfig.value = {
            ...newVal,
            items: moduleHelpers.addIdsToModules(newVal.items ?? []),
        }
    }, { immediate: true })

    watch(activeFilters, filtersChanged, { deep: true })

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(listConfig.value)
        search.value = new URLSearchParams(moduleStore.url).get('search') || ''

        filterStore.initialiseFilters(filters.value, listConfig.value.meta?.id)
            .then((uuid) => {
                filterUuid.value = uuid
            })
    })

    onUnmounted(() => {
        if (filterUuid.value) {
            filterStore.clearFilters(filterUuid.value)
        }
    })
</script>
