<template>
    <IonHeader class="ion-no-border" data-cy="filters-modal">
        <div class="w-full font-medium text-md border-b border-b-transparent transition-colors duration-300 py-2">
            <div class="w-full flex items-center justify-between px-6 overflow-hidden">
                <div class="w-[50px] shrink">
                    <div class="w-[32px] h-[38px] flex items-center justify-center rounded-full" @click="cancel">
                        <PhIcon icon="x" weight="light" :size="24" />
                    </div>
                </div>

                <span
                    class="flex-1 truncate text-gray-900 font-medium"
                    :class="isIos ? 'text-center' : 'text-left'"
                >
                    Filter
                </span>
            </div>
        </div>
    </IonHeader>
    <IonContent>
        <div class="py-3 px-6 pt-0">
            <DForm>
                <div v-for="filter in filtersWithComponent" :key="filter.id" :data-cy="`filters-modal-filter-${filter.type}`">
                    <component v-if="filter.component" :is="filter.component" :filter="filter" :filter-uuid="filterUuid" :model-value="localValue[filter.id]" @update:modelValue="updateFilterValue(filter, $event)" />
                </div>
            </DForm>
        </div>
    </IonContent>
    <IonFooter class="ion-no-border border-t border-base border-solid">
        <div class="flex w-full justify-between p-3">
            <DButton
                theme="transparent"
                :disabled="!canClearAll"
                data-cy="filters-modal-clear-all"
                @click="clearAll"
            >
                Clear all
            </DButton>
            <DButton
                @click="confirm"
                data-cy="filters-modal-confirm"
            >
                Show results
            </DButton>
        </div>
    </IonFooter>
</template>
<script setup>
    import { cloneDeep, keys } from 'lodash'
    import { DForm, DButton } from '@digistorm/spark'
    import { modalController } from '@ionic/vue'
    import { useFilterStore } from '@/stores/filter'
    import { useFilters } from '@/composables'

    const props = defineProps({
        filterUuid: String,
        filters: Array,
    })

    const deviceStore = useDeviceStore()
    const filterStore = useFilterStore()
    const { modalComponentForFilter } = useFilters()

    const { isIos } = storeToRefs(deviceStore)

    const localValue = ref()

    const activeFilters = computed(() => {
        return filterStore.filtersForUuid(props.filterUuid)
    })

    const activeFilterCount = computed(() => {
        return keys(activeFilters.value).length
    })

    const filtersWithComponent = computed(() => {
        return props.filters.map((filter) => {
            return {
                ...filter,
                component: modalComponentForFilter(filter),
            }
        })
    })

    const canClearAll = computed(() => {
        return activeFilterCount.value || keys(localValue.value).length
    })

    const cancel = () => modalController.dismiss(null, 'cancel')

    const confirm = () => {
        filterStore.updateFilters(props.filterUuid, localValue.value)
        modalController.dismiss(null, 'confirm')
    }

    const clearAll = () => {
        filterStore.updateFilters(props.filterUuid, {})
        cancel()
    }

    const updateFilterValue = (filter, filterValue) => {
        if (filterValue === null) {
            delete localValue.value[filter.id]
            return
        }
        localValue.value[filter.id] = filterValue
    }

    watch(activeFilters, () => {
        localValue.value = cloneDeep(activeFilters.value || {})
    }, { immediate: true })
</script>
