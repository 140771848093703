import {
    intersection, isArray, some,
} from 'lodash'
import { modalController } from '@ionic/vue'

import filterType from '@/constants/filterType'
import QuickFiltersModal from '@/components/filters/QuickFiltersModal.vue'
import FiltersModal from '@/components/filters/FiltersModal.vue'

import FilterTypeText from '@/components/filters/modal/FilterTypeText.vue'
import FilterTypeSelect from '@/components/filters/modal/FilterTypeSelect.vue'
import FilterTypeSelectList from '@/components/filters/modal/FilterTypeSelectList.vue'
import FilterTypeSelectScrollableButtons from '@/components/filters/modal/FilterTypeSelectScrollableButtons.vue'

export function useFilters() {
    function modalComponentForFilter(filter) {
        if (filter.type === filterType.select) {
            if (filter.options.length > 25) {
                return FilterTypeSelect
            }
            if (filter.options.length > 8) {
                return FilterTypeSelectList
            }
            return FilterTypeSelectScrollableButtons
        }

        const types = {
            [filterType.text]: FilterTypeText,
        }

        return types[filter.type]
    }

    async function openQuickFilterModal(uuid, filter) {
        const modal = await modalController.create({
            cssClass: 'ds-modal-bottom-sheet',
            component: QuickFiltersModal,
            componentProps: {
                filterUuid: uuid,
                filter,
            },
            initialBreakpoint: 1,
            breakpoints: [0, 1],
        })

        await modal.present()
    }

    async function openFilterModal(uuid, filters) {
        const modal = await modalController.create({
            // Adding ion-disable-focus-trap to prevent focus trap from preventing DSelect search input focus in a modal
            cssClass: 'ds-modal-filters ion-disable-focus-trap',
            component: FiltersModal,
            componentProps: {
                filterUuid: uuid,
                filters,
            },
        })

        await modal.present()
    }

    // This only runs if there's no filter URL and filtering is active
    function applyLocalFilter(filtersById, activeFilters, tags) {
        return some(activeFilters, (filterValue, filterId) => {
            // Only filter by select for now
            if (filtersById[filterId]?.type !== filterType.select) {
                return false
            }

            // Return if there's some matching tags
            return intersection(
                tags ?? [],
                isArray(filterValue)
                    ? filterValue
                    : [filterValue],
            ).length > 0
        })
    }

    return {
        modalComponentForFilter,
        openQuickFilterModal,
        openFilterModal,
        applyLocalFilter,
    }
}
