<template>
    <DField :label="filter.title" :id="filter.id">
        <DSelect
            :options="options"
            :multiple="filter.multiple"
            nullable
            :model-value="localValue"
            @update:modelValue="change"
        />
    </DField>
</template>

<script setup>
    import { cloneDeep, isEmpty, isEqual } from 'lodash'
    import { DField, DSelect } from '@digistorm/spark'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        filterUuid: String,
        filter: Object,
        modelValue: [String, Array],
    })

    const localValue = ref()

    const options = computed(() => {
        return map(props.filter.options, (option) => {
            return { value: option.value, title: option.title }
        })
    })

    const change = (value) => {
        if (isEmpty(value)) {
            value = null
        }
        emit('update:modelValue', value)
    }

    watch(() => props.modelValue, (value) => {
        if (isEqual(value, localValue.value)) {
            return
        }

        localValue.value = props.filter.multiple
            ? cloneDeep(value ?? [])
            : value ?? null
    }, { immediate: true })
</script>
