<template>
    <div class="fixed inset-0 bg-white z-[9999]">
        <div class="font-medium text-md flex items-center justify-between px-3 min-h-header z-10 overflow-hidden pt-[var(--beyond-safe-area-inset-top)] border-b border-b-transparent transition-colors duration-300 absolute inset-x-0">
            <div class="w-[50px]">
                <div class="w-[38px] h-[38px] flex items-center justify-center rounded-full bg-white" @click="close">
                    <PhIcon v-if="isIos" icon="caret-left" weight="regular" :size="24" />
                    <PhIcon v-else icon="arrow-left" weight="regular" :size="24" />
                </div>
            </div>

            <span
                class="flex-1 truncate text-gray-900 font-medium"
                :class="isIos ? 'text-center' : 'text-left'"
            >
                {{currentIndex}}/{{props.gallery.images.length}}
            </span>

            <div class="w-[50px]" />
        </div>

        <Swiper
            :modules="modules"
            :initial-slide="props.gallery.start"
            :style="{
                '--swiper-navigation-color': '#fff',
            }"
            zoom
            loop
            @slide-change="onSlideChange"
        >
            <SwiperSlide v-for="(image, index) in props.gallery.images" :key="index">
                <div class="swiper-zoom-container">
                    <img :src="image.url" :alt="image.title" />
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Zoom, Navigation } from 'swiper'
    import { statusBar } from '@/services'

    import 'swiper/css'

    import 'swiper/css/zoom'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'

    const props = defineProps({
        gallery: Object,
    })

    const deviceStore = useDeviceStore()
    const { isAndroid, isIos } = storeToRefs(deviceStore)

    const { close } = useGallery()

    const modules = [Zoom, Navigation]

    const currentIndex = ref(1)

    const onSlideChange = (swiper) => {
        currentIndex.value = swiper.realIndex + 1
    }

    onBeforeMount(() => {
        statusBar.hide()
    })

    onBeforeUnmount(() => {
        statusBar.show()
    })
</script>

<style>
    .swiper {
        width: 100%;
        height: 100%;
    }
</style>
