<template>
    <div
        class="min-w-[38px] h-[38px] flex items-center justify-center rounded-full"
        :class="{ 'px-3 ring-1 ring-inset bg-[--color-50] text-[--color-700] ring-overlay-brand-20': showFilterIcon && activeFilterCount }"
        @click="openFilters"
        data-cy="filters-button"
    >
        <PhIcon v-if="showFilterIcon" icon="funnel" :weight="activeFilterCount ? 'fill' : 'regular'" :size="24" />
        <PhIcon v-else icon="magnifying-glass" weight="regular" :size="24" />

        <span v-if="showFilterIcon && activeFilterCount">
            {{ activeFilterCount }}
        </span>
    </div>
</template>

<script setup>
    import { some } from 'lodash'
    import { DButton } from '@digistorm/spark'

    import PhIcon from '@/components/app/PhIcon.vue'
    import { useFilters } from '@/composables'
    import { useFilterStore } from '@/stores'
    import filterType from '@/constants/filterType'

    const props = defineProps({
        uuid: String,
        filters: Array,
    })

    const filterStore = useFilterStore()
    const { openQuickFilterModal, openFilterModal } = useFilters()

    const activeFilters = computed(() => {
        return filterStore.filtersForUuid(props.uuid) ?? {}
    })

    const activeFilterCount = computed(() => {
        // If there is only one filter, and it is a select filter with multiple options, show the count of selected options
        if (props.filters.length === 1 && props.filters[0].type === filterType.select && props.filters[0].multiple) {
            return activeFilters.value[props.filters[0].id]?.length ?? 0
        }
        return keys(activeFilters.value).length
    })

    const showFilterIcon = computed(() => {
        return !some(props.filters, (filter) => filter.type === filterType.text)
    })

    const openFilters = async () => {
        if (props.filters.length === 1 && props.filters[0].type === filterType.select) {
            openQuickFilterModal(props.uuid, props.filters[0])
            return
        }

        openFilterModal(props.uuid, props.filters)
    }
</script>
