<template>
    <DField :label="!isSearch ? filter.title : null" :id="filter.id">
        <DInput v-if="isSearch" left-icon="magnifying-glass" :placeholder="filter.title" :model-value="modelValue" @update:modelValue="handleChange" />
        <DInput v-else :value="modelValue" @update:modelValue="handleChange" />
    </DField>
</template>

<script setup>
    import { isEmpty } from 'lodash'
    import { DField, DInput } from '@digistorm/spark'
    import { useFilterStore } from '@/stores/filter'
    import filterInputType from '@/constants/filterInputType'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        filterUuid: String,
        filter: Object,
        modelValue: String,
    })

    const filterStore = useFilterStore()

    const isSearch = computed(() => props.filter.input_type === filterInputType.search)

    const handleChange = (value) => {
        if (isEmpty(value)) {
            value = null
        }
        emit('update:modelValue', value)
    }
</script>
