<template>
    <div class="pt-6 h-[500px] flex flex-col" data-cy="quick-filters-modal">
        <div class="pt-2 pb-4 px-4 border-b border-transparent transition-colors duration-150" :class="{ '!border-gray-300': scrollY > 0 }">
            <p class="text-lg font-medium mb-1">{{ filter.title }}</p>
            <div class="flex w-full items-center">
                <DBadge color="gray" :style="{ '--radius-button-sm': '2px' }">{{ selectedOptions?.length ?? 0 }} selected</DBadge>
                <DButton v-if="filter.multiple" @click="toggleAll" theme="text-link" class="ml-auto" data-cy="toggle-all">{{ allSelected ? 'Deselect all' : 'Select all' }}</DButton>
            </div>
        </div>
        <IonContent ref="contentScroll" class="flex-1" scroll-events @ion-scroll="onScroll">
            <div class="pb-[var(--beyond-safe-area-inset-top)]">
                <UISimple
                    v-for="option in optionsWithEnabled"
                    :key="option.value"
                    @click="toggle(option.value)"
                >
                    <template v-slot:title>{{ option.title }}</template>
                    <template v-slot:right-content>
                        <DCheckbox v-if="filter.multiple" :model-value="option.enabled" class="pointer-events-none scale-[1.15] !mr-2" />
                        <DSwitch v-else :model-value="option.enabled" class="pointer-events-none" />
                    </template>
                </UISimple>
            </div>
        </IonContent>
    </div>
</template>
<script setup>
    import {
        DBadge, DButton, DSwitch, DCheckbox,
    } from '@digistorm/spark'
    import {
        includes, isArray, omit,
    } from 'lodash'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'
    import { useFilterStore } from '@/stores/filter'

    const props = defineProps({
        filterUuid: String,
        filter: Object,
    })

    const filterStore = useFilterStore()

    const scrollY = ref(0)

    const activeFilters = computed(() => {
        return filterStore.filtersForUuid(props.filterUuid)
    })

    const selectedOptions = computed(() => {
        const value = activeFilters.value[props.filter.id] ?? []

        // Always work with an array internally as it's easier to handle
        return isArray(value)
            ? value
            : [value]
    })

    const allSelected = computed(() => {
        return selectedOptions.value.length === props.filter.options.length
    })

    const optionsWithEnabled = computed(() => {
        return map(props.filter.options ?? [], (option) => ({
            ...option,
            enabled: allSelected.value || (selectedOptions.value.includes(option.value) ?? false),
        }))
    })

    const onScroll = (e) => {
        scrollY.value = e.detail.scrollTop
    }

    const updateFilters = (val) => {
        if (!val.length) {
            filterStore.updateFilters(props.filterUuid, omit(activeFilters.value, [props.filter.id]))
            return
        }

        filterStore.updateFilters(props.filterUuid, {
            ...activeFilters.value,
            [props.filter.id]: props.filter.multiple
                ? val
                : val[0],
        })
    }

    const toggleAll = () => {
        if (allSelected.value) {
            updateFilters([])
        } else {
            updateFilters(map(props.filter.options, 'value'))
        }
    }

    const toggle = (value) => {
        if (!props.filter.multiple) {
            if (includes(selectedOptions.value, value)) {
                updateFilters([])
            } else {
                updateFilters([value])
            }
            return
        }

        if (includes(selectedOptions.value, value)) {
            updateFilters(selectedOptions.value.filter((val) => val !== value))
        } else {
            updateFilters([...selectedOptions.value, value])
        }
    }
</script>
