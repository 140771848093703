<template>
    <div
        v-if="avatar.image_url"
        class="flex-shrink-0 rounded-full bg-cover bg-center"
        :class="classes"
        :style="{ backgroundImage: `url('${avatar.image_url}'` }"
    />
    <div
        v-else
        class="flex-shrink-0 rounded-full bg-[--color-100] flex items-center justify-center text-[--color-700]"
        :class="classes"
    >
        <span>{{ avatar.text }}</span>
    </div>
</template>

<script setup>
    const props = defineProps({
        avatar: Object,
    })

    const classes = computed(() => {
        switch (props.avatar.size) {
            case 'small':
                return 'w-[28px] h-[28px] text-xs'
            case 'large':
                return 'w-[64px] h-[64px] text-base'
            case 'medium-small':
                return 'w-[38px] h-[38px] text-xs'
            case 'medium':
            default:
                return 'w-[46px] h-[46px] text-sm'
        }
    })
</script>
