<template>
    <div
        class="-mx-4 px-4 py-3.5 flex items-center transition-colors duration-150"
        :class="{
            'hover:bg-gray-100 active:bg-gray-100': !!item.link,
        }"
        @click.stop="emit('click', item)"
        data-cy="card-detail-text"
    >
        <div class="space-y-1 flex-1">
            <p class="text-gray-900 font-medium text-sm flex items-center justify-between" style="overflow-wrap: anywhere;">
                <span><slot name="title">{{ item.title }}</slot></span>
                <slot name="indicators" />
            </p>
            <p v-if="$slots.body || item.body" style="overflow-wrap: anywhere;">
                <slot name="body"><UIHtml :html="item.body" class="prose prose-sm text-gray-600 text-[13px]" /></slot>
            </p>
        </div>

        <div v-if="item.right_icon" class="ml-auto pl-2">
            <PhIcon :icon="item.right_icon" class="text-[--color-700]" :size="24" weight="regular" />
        </div>
    </div>
</template>

<script setup>
    import UIHtml from '@/components/ui/UIHtml.vue'

    const emit = defineEmits(['click'])

    const props = defineProps({
        item: Object,
    })
</script>
