<template>
    <DField :label="filter.title" :id="filter.id">
        <template v-if="filter.multiple">
            <DCheckbox
                v-for="option in options"
                :key="option.value"
                :model-value="option.active"
                @update:modelValue="toggleOption(option)"
            >
                {{ option.title }}
            </DCheckbox>
        </template>
        <template v-else>
            <!-- Use a unique id for each option, so that DRadio will work for our needs (we don't want the default behaviour) -->
            <DRadio
                v-for="option in options"
                :key="option.value"
                :model-value="option.active"
                @click="toggleOption(option)"
                :native-value="true"
            >
                {{ option.title }}
            </DRadio>
        </template>

        <a @click="expanded = !expanded" href="#" class="cursor-pointer font-medium text-sm text-[--color-700] underline">
            {{ expanded ? 'Show less' : 'Show more' }}<PhIcon :icon="expanded ? 'caret-up' : 'caret-down'" :size="15" weight="bold" class="inline-block ml-1" />
        </a>
    </DField>
</template>

<script setup>
    import {
        cloneDeep, isEmpty, isEqual, includes, map, take, chain,
    } from 'lodash'
    import { DField, DCheckbox, DRadio } from '@digistorm/spark'
    import PhIcon from '@/components/app/PhIcon.vue'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        filterUuid: String,
        filter: Object,
        modelValue: [String, Array],
    })

    const localValue = ref()
    const expanded = ref(false)

    const options = computed(() => {
        return chain(props.filter.options)
            .map((option) => {
                return {
                    ...option,
                    active: props.filter.multiple
                        ? includes(localValue.value, option.value)
                        : localValue.value === option.value,
                }
            })
            .take(expanded.value ? props.filter.options.length : 3)
            .value()
    })

    const change = (value) => {
        if (isEmpty(value)) {
            value = null
        }
        localValue.value = value
        emit('update:modelValue', value)
    }

    const toggleOption = (option) => {
        if (!props.filter.multiple) {
            if (!option.active) {
                change(option.value)
            } else {
                change(null)
            }
            return
        }

        if (!option.active) {
            change([...(localValue.value ?? []), option.value])
        } else {
            change(localValue.value.filter((value) => value !== option.value))
        }
    }

    watch(() => props.modelValue, (value) => {
        if (isEqual(value, localValue.value)) {
            return
        }

        localValue.value = props.filter.multiple
            ? cloneDeep(value ?? [])
            : value ?? null
    }, { immediate: true })
</script>
