<template>
    <div class="py-3 overflow-x-auto scrollbar-none" data-cy="filters-bar">
        <div class="px-3 inline-flex w-max space-x-2 h-8">
            <DButton
                v-if="filters.length > 1"
                class="shrink-0 cursor-pointer"
                size="sm"
                :theme="activeFilterCount ? 'outline' : 'secondary'"
                @click="handleFilters"
                data-cy="filters-bar-filters"
            >
                <PhIcon icon="sliders-horizontal" :size="15" weight="bold" class="inline-block mr-1" />
                Filter
                <DBadge v-if="activeFilterCount" color="invert" class="ml-1 -mr-1" rounded>{{ activeFilterCount }}</DBadge>
            </DButton>

            <DButton
                v-for="item in barItems"
                :key="item.id"
                class="shrink-0 cursor-pointer"
                size="sm"
                :theme="item.active ? 'outline' : 'secondary'"
                @click="item.onClick"
                :data-cy="item.active ? 'filters-bar-filter-active' : 'filters-bar-filter-inactive'"
            >
                {{ item.title }}
                <template v-if="item.count">
                    <DBadge v-if="item.count" color="invert">{{ item.count }}</DBadge>
                    <PhIcon icon="caret-down" :size="15" weight="bold" class="inline-block ml-1" />
                </template>
                <template v-else>
                    <PhIcon :icon="item.active ? 'x' : 'caret-down'" :size="15" weight="bold" class="inline-block ml-1" />
                </template>
            </DButton>
        </div>
    </div>
</template>

<script setup>
    import { flatMap, keys, omit } from 'lodash'
    import { DButton, DBadge } from '@digistorm/spark'

    import PhIcon from '@/components/app/PhIcon.vue'
    import { useFilters } from '@/composables'
    import { useFilterStore } from '@/stores/filter'
    import filterType from '@/constants/filterType'

    const props = defineProps({
        uuid: String,
        filters: Array,
    })

    const filterStore = useFilterStore()
    const { openQuickFilterModal, openFilterModal } = useFilters()

    const activeFilters = computed(() => {
        return filterStore.filtersForUuid(props.uuid) ?? {}
    })

    const activeFilterCount = computed(() => {
        return keys(activeFilters.value).length
    })

    const filterIsActive = (filter) => {
        return activeFilters.value[filter.id] !== undefined
    }

    const removeFilter = (filter) => {
        filterStore.updateFilters(props.uuid, omit(activeFilters.value, filter.id))
    }

    const addFilter = (filter) => {
        openQuickFilterModal(props.uuid, filter)
    }

    const removeFilterOption = (filter, option) => {
        const options = activeFilters.value[filter.id].filter((value) => value !== option.value)

        if (!options.length) {
            removeFilter(filter)
            return
        }

        filterStore.updateFilters(props.uuid, {
            ...activeFilters.value,
            [filter.id]: options,
        })
    }

    const barItems = computed(() => {
        return flatMap(props.filters, (filter) => {
            let active = filterIsActive(filter)
            if (active && filter.type === filterType.select && filter.multiple && !activeFilters.value[filter.id]?.length) {
                active = false
            }

            const filterItem = {
                id: filter.id,
                title: filter.title,
                active,
                onClick: active ? () => removeFilter(filter) : () => addFilter(filter),
            }

            // Override the title for text filters
            if (filter.type === filterType.text) {
                if (!active) {
                    return []
                }
                filterItem.title = activeFilters.value[filter.id]
            }

            // Add title for single-select filters
            if (filter.type === filterType.select && !filter.multiple && active) {
                filterItem.title = activeFilters.value[filter.id]
            }

            // Add multiple items for multi-select filters
            if (filter.type === filterType.select && filter.multiple && active) {
                return [
                    {
                        ...filterItem,
                        count: activeFilters.value[filter.id]?.length,
                        onClick: () => addFilter(filter),
                    },
                ]
            }

            return [filterItem]
        })
    })

    const handleFilters = () => {
        openFilterModal(props.uuid, props.filters)
    }
</script>
