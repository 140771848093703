<template>
    <div data-cy="simple-list" data-list-item="simple" class="active:bg-gray-100 hover:bg-gray-100">
        <div
            class="flex items-center space-x-3 py-5 mx-4 border-gray-200"
            @click="emit('click')"
        >
            <UIAvatar v-if="avatar" :avatar="avatar" />

            <div v-if="icon">
                <UIIcon :icon="icon" class="text-[--color-700]" :size="24" weight="light" />
            </div>

            <div class="space-y-1 flex-1">
                <p class="text-gray-900 font-regular flex items-center justify-between">
                    <span><slot name="title">{{ title }}</slot></span>
                    <span class="font-normal text-gray-600 text-sm"><slot name="indicators" /></span>
                </p>
                <p v-if="$slots.subtitle || subtitle" class="text-gray-600 text-sm">
                    <slot name="subtitle">{{ subtitle }}</slot>
                </p>
            </div>
            <div v-if="unreadCount" class="text-sm font-medium flex items-center justify-center w-6 h-6 bg-red-700 text-white rounded-full" data-cy="group-notification">
                {{unreadCount}}
            </div>
            <div class="flex items-center ml-auto" :class="{ 'space-x-2': !$slots.indicators }">
                <span class="text-gray-600"><slot name="right-text" /></span>
                <slot name="right-content">
                    <UIIcon v-if="rightIcon" :icon="rightIcon" class="text-gray-500" :size="24" weight="light" />
                </slot>
            </div>
        </div>
    </div>
</template>

<script setup>
    import UIAvatar from '@/components/ui/UIAvatar.vue'
    import UIIcon from '@/components/ui/UIIcon.vue'

    const emit = defineEmits(['click'])

    const props = defineProps({
        title: String,
        subtitle: String,
        image: String,
        avatar: Object,
        icon: {
            type: Object,
            default: null,
        },
        rightIcon: {
            type: Object,
            default: null,
        },
        unreadCount: Number,
    })
</script>

<style lang="scss">
    [data-list-item] + [data-list-item="simple"] > div,
    .vue-recycle-scroller__item-view [data-previous="SimpleListItem"][data-list-item="simple"] > div {
        @apply border-t;
    }
</style>
