<template>
    <Teleport :to="headerMenuElement">
        <div class="w-[38px] h-[38px] flex items-center justify-center rounded-full" @click="goToNextStep(false)" data-cy="tour-skip">
            Skip
        </div>
    </Teleport>
    <div class="flex flex-col flex-grow bg-gray-100 min-h-full" data-cy="tour-component">
        <component
            v-if="props.config"
            ref="layoutComponent"
            :is="layoutComponentType"
            :config="props.config"
            :data-cy="`tour-layout-${tourStore.currentLayoutType}`"
            @action-completed="goToNextStep"
            @action-skipped="goToNextStep"
        />
    </div>
</template>
<script setup>
    import { useRouter } from 'vue-router'

    import { useTourStore } from '@/stores/tour'
    import { useModuleHelpers } from '@/composables'
    import * as layouts from '@/components/ui/TourLayouts'

    const props = defineProps({
        config: Object,
        class: String,
        dataCy: String,
    })

    const router = useRouter()
    const moduleStore = useModuleStore()
    const tourStore = useTourStore()
    const moduleHelpers = useModuleHelpers()

    const headerMenu = inject('headerMenu')
    const layoutComponent = ref(null)

    const headerMenuElement = computed(() => {
        return headerMenu?.value?.$el?.querySelector('#header-menu')
    })

    const layoutComponentType = computed(() => {
        return layouts[props.config.layout.type]
    })

    const goToNextStep = async (replace = false) => {
        const nextStepModule = await tourStore.getNextStepModule()

        if (!nextStepModule) {
            tourStore.completeTour()
            moduleHelpers.navigateBackToRoot()
            moduleStore.reset()
            return
        }

        if (replace) {
            moduleStore.replaceLastModule(nextStepModule)
            router.replace({ name: 'view', params: { id: nextStepModule.id } })
            return
        }
        moduleStore.addModule(nextStepModule)
        router.push({ name: 'view', params: { id: nextStepModule.id } })
    }
</script>
