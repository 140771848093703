export function addCypressWindowListener(pluginName, event, callback) {
    if (!window.Cypress) {
        return () => {}
    }

    // Custom event data goes into the detail key
    const listener = (e) => callback(e.detail)
    window.addEventListener(`${pluginName}:${event}`, listener)

    return () => {
        window.removeEventListener(`${pluginName}:${event}`, listener)
    }
}
