<template>
    <IonPage>
        <div class="relative h-screen flex flex-col items-center justify-center p-6 !pt-[var(--beyond-safe-area-inset-top)] !pb-[var(--beyond-safe-area-inset-bottom)]" :style="{ backgroundColor }" data-cy="login">
            <template v-if="authStore.browserAuthLoading">
                <UILoading class="w-12 h-12" />
            </template>
            <template v-else>
                <div v-if="loginAbortHandler" class="absolute right-0 top-[var(--beyond-safe-area-inset-top)] p-2">
                    <DButton @click="goBack" icon="x-mark" theme="transparent" :background="useDarkText ? null : 'invert'" />
                </div>

                <div v-if="logoUrl" class="px-12">
                    <img :src="logoUrl" alt="logo" class="max-w-5xs" />
                </div>

                <div :class="{ 'bg-white': type !== 'url' }" class="rounded-lg px-4 py-6 w-full mt-12 flex flex-col items-center max-w-md">
                    <p v-if="version.login_help_text" class="mb-6 text-sm break-words max-w-full text-center">
                        {{ version.login_help_text }}
                    </p>
                    <template v-if="type === 'url'">
                        <DButton data-cy="url-auth-start" @click="startBrowserAuth" size="lg" wide>
                            Sign in
                        </DButton>
                    </template>
                    <DForm v-else-if="type === 'basic'" @submit="submit" :errors="errors" class="w-full flex flex-col items-center">
                        <DField id="username">
                            <DInput
                                v-model="username"
                                data-cy="username"
                                placeholder="Username"
                                autocorrect="off"
                                autocapitalize="off"
                            />
                        </DField>
                        <DField id="password">
                            <DInput
                                v-model="password"
                                placeholder="Password"
                                data-cy="password"
                                type="password"
                                autocorrect="off"
                                autocapitalize="off"
                                @keydown.enter="submit"
                            />
                        </DField>

                        <div v-if="version.login_checkbox_html" class="w-full mt-5">
                            <DCheckbox
                                v-model="checkboxChecked"
                                checkbox-position="self-start"
                            >
                                <UIHtml :html="version.login_checkbox_html" class="break-words -mt-1 prose font-regular text-sm prose-strong:text-gray-600 text-gray-600 prose-a:text-[--color-600] prose-a:font-regular" />
                            </DCheckbox>
                        </div>

                        <DButton v-if="!loading" :disabled="!canSignIn" data-cy="submit" @click="submit" size="lg" wide>
                            Sign in
                        </DButton>
                        <UILoading v-else class="w-12 h-12" />

                        <template v-if="version.login_links">
                            <template
                                v-for="(link, index) in version.login_links"
                                :key="index"
                            >
                                <div
                                    v-if="link.link"
                                    @click="linkClicked(link)"
                                    class="text-[--color-600] text-sm font-medium underline mt-5 cursor-pointer"
                                >
                                    {{ link.text }}
                                </div>
                            </template>
                        </template>

                    </DForm>
                    <div v-else>
                        <DAlert type="danger" title="Error">
                            Unexpected authentication type, something has gone wrong
                        </DAlert>
                    </div>
                </div>
            </template>
            <div class="absolute bottom-[var(--beyond-safe-area-inset-bottom)] inset-x-0 px-2 py-2 text-xs flex items-center justify-end" :class="useDarkText ? 'text-gray-800' : 'text-white'">
                <span class="w-8 h-8 flex items-center justify-center" @click="openSettings">
                    <DIcon icon="cog-6-tooth" class="!flex" />
                </span>
            </div>
        </div>
    </IonPage>
</template>

<script setup>
    import tinycolor from 'tinycolor2'
    import {
        DIcon, DForm, DAlert, DCheckbox, DInput, DField, DButton,
    } from '@digistorm/spark'

    import { preferences, statusBar } from '@/services'

    const appStore = useAppStore()
    const authStore = useAuthStore()
    const moduleStore = useModuleStore()
    const versionStore = useVersionStore()

    const moduleHelpers = useModuleHelpers()
    const { homepageAnimation } = useAnimations()

    const router = useIonRouter()

    const { appId, mockData } = storeToRefs(appStore)
    const { authRequirements, loginSuccessHandler, loginAbortHandler } = storeToRefs(authStore)
    const { version } = storeToRefs(versionStore)

    const { snackbar } = useSnackbars()

    const type = computed(() => {
        return authRequirements.value.type
    })

    const url = computed(() => {
        return authRequirements.value.url
    })

    const username = ref('')
    const password = ref('')
    const checkboxChecked = ref(false)

    const loading = ref(false)
    const errors = ref(null)
    const checkAppViewerUrlAuthTimeout = ref(null)

    const backgroundColor = computed(() => {
        const backgroundColor = get(version.value, 'background_color')
        if (isEmpty(backgroundColor)) {
            return '#ffffff'
        }
        return backgroundColor
    })

    const useDarkText = computed(() => {
        const color = tinycolor(backgroundColor.value)
        return color.isLight()
    })

    const logoUrl = computed(() => {
        return get(version.value, 'logo_file.url')
    })

    const canSignIn = computed(() => {
        const checkboxRequired = get(version.value, 'login_checkbox_html')
        if (!checkboxRequired) {
            return true
        }
        return checkboxChecked.value
    })

    const checkAppViewerUrlAuthCompletedExternally = async () => {
        console.log('Checking local storage to see if user has logged in')

        // Check if completed and trigger the login event
        const urlAuthCompletedEvent = await preferences.getGlobalItem('url_auth_completed_event')
        if (urlAuthCompletedEvent) {
            const payload = JSON.parse(urlAuthCompletedEvent)

            // NOTE: Timeout required as the other window closing at the same time this window navigates
            // seems to cause a weird bug where this windows router doesn't update correctly
            setTimeout(() => {
                authStore.events.emit('login', payload)
                loading.value = false
            }, 1000)
            return
        }

        // Wait and check again
        checkAppViewerUrlAuthTimeout.value = setTimeout(() => checkAppViewerUrlAuthCompletedExternally(), 250)
    }

    const startBrowserAuth = async () => {
        authStore.start({ url: url.value })

        if (authStore.shouldUseAppViewerUrlAuthProcess) {
            loading.value = true
            await preferences.removeGlobalItem('url_auth_completed_event')
            checkAppViewerUrlAuthCompletedExternally()
        }
    }

    const submit = () => {
        loading.value = true
        errors.value = null

        authStore.login({
            url: url.value,
            username: username.value,
            password: password.value,
        })
    }

    const goBack = () => {
        loginAbortHandler.value?.()
    }

    const handleLogin = (event) => {
        loginSuccessHandler.value?.(event)
    }

    const handleError = (e) => {
        if (get(e, 'response.status') === 422 && get(e, 'response.data.errors')) {
            errors.value = get(e, 'response.data.errors')
        }

        if (moduleHelpers.isActionException(e)) {
            moduleHelpers.handleActionException(
                e.response.data,
            )
        } else {
            snackbar({
                message: 'Invalid username or password',
                type: 'error',
            })
        }

        loading.value = false
    }

    const handleAbort = (e) => {
        // No action required
    }

    const openSettings = () => {
        // set active module url
        moduleStore.addModule({
            id: 'settings',
            name: 'Settings',
            settings: [],
            link: {
                type: 'internal',
                url: `/${appId.value}/settings`,
            },
        })

        router.push({ name: 'view', params: { id: 'settings' } }, homepageAnimation)
    }

    const linkClicked = (link) => {
        if (!link.link) {
            return
        }

        moduleHelpers.getModuleOrOpenBrowserForLinkData(link.link)
            .then((appModule) => {
                if (!appModule || !appModule.id) {
                    return
                }
                router.push({ name: 'view', params: { id: appModule.id } }, homepageAnimation)
            })
    }

    onMounted(() => {
        authStore.events.on('login', handleLogin)
        authStore.events.on('error', handleError)
        authStore.events.on('abort', handleAbort)

        statusBar.setTheme(backgroundColor.value)

        // Automatically start browser auth if relevant
        if (type.value === 'url') {
            startBrowserAuth()
        }
    })

    onUnmounted(() => {
        authStore.events.off('login', handleLogin)
        authStore.events.off('error', handleError)
        authStore.events.off('abort', handleAbort)

        // Clear auth check timeout
        if (checkAppViewerUrlAuthTimeout.value !== null) {
            clearTimeout(checkAppViewerUrlAuthTimeout.value)
        }
    })

    onIonViewWillEnter(() => {
        // Try handle login if mockdata is enabled
        if (mockData.value) {
            handleLogin({
                userSwitched: true,
            })
        }
    })
</script>
