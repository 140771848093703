<template>
    <DField :label="filter.title" :id="filter.id">
        <div class="-mx-6 pt-2 py-1 overflow-x-auto scrollbar-none" tabindex="-1">
            <div class="px-6 inline-flex w-max space-x-2 h-8">
                <DButton
                    v-for="option in options"
                    :key="option.value"
                    class="shrink-0"
                    size="sm"
                    :theme="option.active ? 'outline' : 'secondary'"
                    @click="toggleOption(option)"
                    tabindex="0"
                >
                    {{ option.title }}
                </DButton>
            </div>
        </div>
    </DField>
</template>

<script setup>
    import {
        cloneDeep, isEmpty, isEqual, includes, map,
    } from 'lodash'
    import { DButton, DField } from '@digistorm/spark'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        filterUuid: String,
        filter: Object,
        modelValue: [String, Array],
    })

    const localValue = ref()

    const options = computed(() => {
        return map(props.filter.options, (option) => {
            return {
                ...option,
                active: props.filter.multiple
                    ? includes(localValue.value, option.value)
                    : localValue.value === option.value,
            }
        })
    })

    const change = (value) => {
        if (isEmpty(value)) {
            value = null
        }
        localValue.value = value
        emit('update:modelValue', value)
    }

    const toggleOption = (option) => {
        if (!props.filter.multiple) {
            if (!option.active) {
                change(option.value)
            } else {
                change(null)
            }
            return
        }

        if (!option.active) {
            change([...(localValue.value ?? []), option.value])
        } else {
            change(localValue.value.filter((value) => value !== option.value))
        }
    }

    watch(() => props.modelValue, (value) => {
        if (isEqual(value, localValue.value)) {
            return
        }

        localValue.value = props.filter.multiple
            ? cloneDeep(value ?? [])
            : value ?? null
    }, { immediate: true })
</script>
